"use strict";

// Reveal sections

const revealSection = function (entries, observer) {
  const [entry] = entries;

  if (!entry.isIntersecting) return;

  entry.target.classList.remove("section--hidden");
  observer.unobserve(entry.target);
};

const sectionObserver = new IntersectionObserver(revealSection, {
  root: null,
  threshold: 0.15,
});

addEventListener("load", function () {
  const allSections = document.querySelectorAll(".section");
  allSections.forEach(function (section) {
    sectionObserver.observe(section);
    section.classList.add("section--hidden");
  });
});

// Sponsor render
const mainSponsor = document.querySelector(".main-sponsor");
const otherSponsor = document.querySelector(".other-sponsor");

// Main sponsor
const getMainSponsor = async function () {
  // Ottenere dati API
  const response = await fetch(
    `${STRAPI_URL}/api/main-sponsor?populate=immagine`
  );
  const newsJson = await response.json();
  const data = newsJson.data;
  const generateMarkup = function () {
    return data.attributes.link
      ? /*html*/ `<a href=${data.attributes.link} target="_blank">
      <img src="${data.attributes.immagine.data.attributes.url}" /></a>`
      : /*html*/ `<img src="${data.attributes.immagine.data.attributes.url}" />`;
  };

  mainSponsor.insertAdjacentHTML("beforeend", generateMarkup());
};

getMainSponsor();

// Other sponsor
const getOtherSponsor = async function () {
  // Ottenere dati API
  const response = await fetch(`${STRAPI_URL}/api/sponsors?populate=immagine`);
  const newsJson = await response.json();
  const data = newsJson.data;
  const generateMarkup = function (i) {
    return data[i].attributes.link
      ? /*html*/ `<a href=${data[i].attributes.link} target="_blank"><div class="sponsor-img">
    <img src="${data[i].attributes.immagine.data[0].attributes.url}" /></div>`
      : /*html*/ `<div class="sponsor-img">
    <img src="${data[i].attributes.immagine.data[0].attributes.url}" /></div>`;
  };
  for (const item of data.entries()) {
    let id = item[1].id;
    let markup = generateMarkup(item[0], id);
    otherSponsor.insertAdjacentHTML("beforeend", markup);
  }
};

getOtherSponsor();
